<template>
  <div style="width:100%;background:#F3F4F5;">
    <!-- 头部导航 -->
    <van-nav-bar
      class="doctor_title"
      title="上传信息"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 图片上传 -->
    <div class="update_box">
      <div class="update_box_img">
        <van-uploader
          v-model="IDcardZ"
          multiple
          :max-count="1"
          preview-size="60vw"
          accept="image/png, image/jpeg,image/jpg"
          :after-read="IDcardZHandle"
        >
          <template #default>
            <div class="img">
              <van-image
                width="16rem"
                height="10rem"
                fit="contain"
                :src="IDZ"
              />
              <p>上传身份证正面照</p>
            </div>
          </template>
        </van-uploader>
      </div>
      <div class="update_box_img">
        <van-uploader
          v-model="IDcardF"
          multiple
          :max-count="1"
          preview-size="60vw"
          accept="image/png, image/jpeg,image/jpg"
          :afterRead="IDcardFHandle"
        >
          <template #default>
            <div class="img">
              <van-image
                width="16rem"
                height="10rem"
                fit="contain"
                :src="IDF"
              />
              <p>上传身份证反面照</p>
            </div>
          </template>
        </van-uploader>
      </div>
      <div class="update_box_img">
        <van-uploader
          v-model="Doctor"
          multiple
          :max-count="1"
          preview-size="60vw"
          accept="image/png, image/jpeg,image/jpg"
          :afterRead="DoctorHandle"
        >
          <template #default>
            <div class="img">
              <van-image
                width="16rem"
                height="10rem"
                fit="contain"
                :src="DOC"
              />
              <p>上传医师执照</p>
            </div>
          </template>
        </van-uploader>
      </div>
    </div>
    <!-- 提交按钮 -->
    <van-button
      round
      color="#32C6E9"
      block
      style="width:90%;margin: 22pt auto;"
      @click="DoctorUpdate"
      >提交</van-button
    >
  </div>
</template>

<script>
import IDZ from '@/assets/images/身份证正面.png'
import IDF from '@/assets/images/身份证背面.png'
import DOC from '@/assets/images/医师执照.png'
import doctorImgUpdate, { doctorImg } from '../services/update.js'
export default {
  data() {
    return {
      IDZ,
      IDF,
      DOC,
      IDcardZ: [],
      IDcardF: [],
      Doctor: [],
      zhengMian: '',
      fanMian: '',
      doctorImg: ''
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    // 身份证正面上传回调
    IDcardZHandle(res) {
      const formData = new FormData()
      formData.append('file', res.file)
      doctorImgUpdate(formData).then(res => {
        this.zhengMian = res.data.data.url
      })
    },
    // 身份证反面上传回调
    IDcardFHandle(res) {
      const formData = new FormData()
      formData.append('file', res.file)
      doctorImgUpdate(formData).then(res => {
        this.fanMian = res.data.data.url
      })
    },
    // 医师执照上传回调
    DoctorHandle(res) {
      const formData = new FormData()
      formData.append('file', res.file)
      doctorImgUpdate(formData).then(res => {
        this.doctorImg = res.data.data.url
      })
    },
    // 上传信息
    DoctorUpdate() {
      if (
        this.zhengMian !== '' &&
        this.fanMian !== '' &&
        this.doctorImg !== ''
      ) {
        doctorImg({
          id_card_just: this.zhengMian,
          id_card_back: this.fanMian,
          doctor_img: this.doctorImg,
          user_id: this.$route.query.user_id
        })
        location.href = 'https://hrp.weilaihos.com/index/user/index.html'
      } else {
        alert('请补充完整信息')
      }
    }
  }
}
</script>

<style>
.back {
  z-index: 100;
}
.doctor_title {
  height: 12vw;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid rgb(184, 180, 180);
  background: #5ca5ff;
}
/* 图片上传 */
.update_box {
  margin-top: 12vw;
}
/* 每一项 */
.update_box_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 6pt 0;
  background: #ffffff;
}
.img {
  margin-top: 2%;
}
.img p {
  text-align: center;
  font-size: 14pt;
}
.van-nav-bar__title {
  color: white;
}
</style>
